import {APIURL} from "@/store/config";

export const meetings = {
    state: {
        meeting: {
            hash: '',
            email: '',
            name: ''
        }
    },
    mutations: {
        meetingHash(state: any, meetingHash: string) {
            state.meeting.hash = meetingHash
        },
        meetingEmail(state: any, meetingEmail: string) {
            state.meeting.email = meetingEmail
        },
        meetingName(state: any, meetingName: string) {
            state.meeting.name = meetingName
        },
    },
    getters: {
        meetingHash(state: any) {
            return state.meeting.hash
        },
        meetingEmail(state: any) {
            return state.meeting.email
        },
        meetingName(state: any) {
            return state.meeting.name
        },
    },
    actions: {
        checkMeeting(context: any, meetingHash: string) {
            return new Promise((resolve, reject) => {
                fetch(`${APIURL}meetings/info/${meetingHash}`, {
                    method: 'GET'
                }).then(res => {
                    if (res.status === 200) {
                        // valid meeting
                        res.json().then(response => {
                            resolve(response);
                        })
                    } else {
                        // invalid meeting
                        reject();
                    }
                })
            })
        },
        confirmMeeting(context: any, data: any) {
            return new Promise<void>((resolve, reject) => {
                fetch(`${APIURL}meetings/confirm/${data.hash}`, {
                    method: 'POST',
                    body: JSON.stringify(data)
                }).then(res => {
                    if (res.status === 200) {
                        res.json().then(data => {
                            resolve(data)
                        })
                    } else {
                        reject()
                    }
                })
            })
        },
        getMeetingByPIN(context: any, pin: string) {
            return new Promise((resolve, reject) => {
                fetch(`${APIURL}meetings/pin/${pin}`, {
                    method: 'GET'
                }).then(res => {
                    if (res.status === 200) {
                        // valid meeting
                        res.json().then(response => {
                            resolve(response);
                        })
                    } else {
                        // invalid meeting
                        reject();
                    }
                })
            })
        },
        requestMeeting(context: any, meetingData: any) {
            return new Promise<void>((resolve, reject) => {
                fetch(`${APIURL}meetings`, {
                    method: 'POST',
                    body: JSON.stringify(meetingData)
                }).then(res => {
                    if (res.status === 200) {
                        res.json().then(res => resolve(res))
                    } else {
                        reject();
                    }
                })
            })
        },
        async confirmPayment(state: any, paymentHash: string) {
            const response = await fetch(`${APIURL}meetings/confirmpayment/${paymentHash}`)

            if (response.ok) {
                return true;
            } else {
                throw new Error('invalid_payment_hash')
            }
        }
    }
}