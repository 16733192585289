
import {defineComponent} from "vue";
import {mapActions, mapGetters} from "vuex";
import Navbar from "@/components/Navbar.vue";
import {Notyf} from "notyf";
import UserTableRow from "@/components/UserTableRow.vue";

const notyf = new Notyf()

export default defineComponent({
  name: "Backend",
  components: {UserTableRow, Navbar},

  data() {
    return {
      componentName: "Backend",
      creating: false,
      newUser: {
        user_id: 0,
        name: '',
        email: '',
        password: '',
        confirmation: '',
        type: 'user',
      }
    }
  },
  computed: {
    ...mapGetters(['getUsers']),
    validPassword(): boolean {
      return (this.newUser.password === this.newUser.confirmation && this.newUser.password.length >= 6)
    }
  },
  methods: {
    ...mapActions(['fetchUsers', 'createUser', 'deleteUser']),
    async deleteBtn(id: string) {
      if (confirm(this.$t('message.confirmUserRemoval'))) {
        //TODO:Create notification to ask if it is really to delete
        try {
          await this.deleteUser(id);
          notyf.success(this.$t('message.userRemoved'))
        } catch (e) {
          notyf.error(this.$t('message.errorRemovingUser'))
        }
      }
    },
    async create() {
      if (this.validPassword) {
        try {
          const create = await this.createUser(this.newUser)
          if (create) {
            this.toggleCreating()
            this.clearForm()
            notyf.success(this.$t('message.newUserAdded'))
          }
        } catch (e) {
          notyf.error(this.$t('message.errorCreatingNewUser'))
        }
      } else {
        notyf.error(this.$t('message.errorPasswordsDontMatch'))
      }
    },
    clearForm() {
      this.newUser = {
        user_id: 0,
        name: '',
        email: '',
        password: '',
        confirmation: '',
        type: 'user',
      }
    },
    toggleCreating() {
      this.creating = !this.creating
    }
  },
  watch: {},
  mounted() {
    this.fetchUsers()
  }
})
