export const pt = {
    message: {
        accessMeeting: `Aceder`,
        alreadyReceivedPIN: `Já recebi o PIN por email`,
        cantActivateMeeting: `Não é possível confirmar!`,
        checkEmail: `Verifique o email introduzido`,
        chiefComplaint: `Motivo para Pedido de Teleaconselhamento`,
        confirmMeeting: `Confirmar Teleaconselhamento`,
        confirmingMeeting: `A confirmar...`,
        connect: `Aceder`,
        connecting: `A ligar...`,
        describeChiefComplaint: `Descreva aqui o motivo para o pedido.`,
        emailExample: `omeuemail@email.pt`,
        emailSentWithAccessDetails: `Foi enviado um email com detalhes.`,
        goBack: `Voltar`,
        imYourSpecialist: `Olá, somos o seu Centro Veterinário!`,
        insertMeetingDateAndTime: `Selecione uma data e hora`,
        insertPINReceivedEmail: `Insira aqui o PIN recebido por email.`,
        insertRegisteredEmail: `Insira o email utilizado`,
        letsSolveCaseTogether: `O seu Centro Veterinário de preferência!`,
        meetingConfirmed: `Confirmado`,
        meetingPIN: `PIN recebido`,
        meetingUnavailable: `Teleaconselhamento indisponível!`,
        meetingUnavailableDetails: `O teleaconselhamento encontra-se <strong>indisponível</strong> de momento!<br><br>Verifique o email recebido e tente novamente no horário agendado!<br><br>Obrigado.`,
        registrationSubtitle: `Fale aqui com o seu veterinário!`,
        registrationTitle: `Teleaconselhamento Veterinário por Videochamada `,
        requestConsultation: `Pedir Teleaconselhamento`,
        scheduleMeeting: `Agendar Teleaconselhamento`,
        thankYou: `Obrigado!`,
        vetSpecialists: `Escolha o seu Centro de Atendimento Médico Veterinário`,
        veterinaryCenter: `Nome do Tutor do Animal`,
        veterinarySurgeon: `Nome do Animal`,
        waitAMomentPlease: `Aguarde um momento por favor...`,
        weWillConfirmAsSoonAsPossibleAndSendEmail: `Brevemente receberá um email com instruções`,
        yourEmail: `Email`,
        yourRequestHasBeenSent: `O pedido de teleaconselhamento foi enviado.`,

        backend: `Backend`,
        createUpdateYourUsers: `Gestão de utilizadores!`,
        newUser: `Novo utilizador`,
        image: `Imagem`,
        specialistName: `Nome do Especialista`,
        email: `Email`,
        operations: `Operações`,
        createNewSpecialist: `Criar Novo Especialista`,
        description: `Descrição`,
        backendPassword: `Palavra-passe`,
        confirmation: `Confirmação`,
        save: `Guardar`,
        cancel: `Cancelar`,

        edit: `Editar`,
        delete: `Remover`,

        confirmUserRemoval: 'Deseja remover este utilizador?',
        userRemoved: `Utilizador removido!`,
        errorRemovingUser: `Erro ao remover o utilizador!`,
        newUserAdded: `Novo utilizador adicionado!`,
        errorCreatingNewUser: `Erro ao criar novo utilizador!`,
        errorPasswordsDontMatch: `As passwords não são iguais!` ,

        userDataSaved: `Utilizador guardado com sucesso!`,
        errorChangingUserData: `Erro ao alterar o utilizador!`,
        errorUploadingImage: `Erro ao enviar imagem!`,
    }
}