
import {defineComponent} from "vue";
import {mapActions} from "vuex";
import {Notyf} from "notyf";

const notyf = new Notyf()

export default defineComponent({
  name: "Login",
  data() {
    return {
      componentName: "Login",
      email: '',
      password: '',
      isChecking: false,
    }
  },
  components: {},
  props: [],
  computed: {},
  methods: {
    ...mapActions(['login']),
    async doLogin() {
      try {
        this.isChecking = true
        const loginStatus = await this.login({
          email: this.email,
          password: this.password,
        })
        this.isChecking = false

        await this.$router.push('/backend')
      } catch (e) {
        notyf.error(`Invalid login!`)
        this.isChecking = false
      }
    }
  },
  watch: {},
  mounted() {
  }
})
