import {createStore} from 'vuex'
import {meetings} from "@/store/modules/store.meetings";
import {users} from "@/store/modules/store.users";

export default createStore({
    modules: {
        meetings,
        users
    }
})
