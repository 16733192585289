import {createApp} from 'vue'
import {createI18n} from "vue-i18n";
import App from './App.vue'
import router from './router'
import store from './store'

// fontawesome
import '@fortawesome/fontawesome-free/css/all.min.css'
import '@fortawesome/fontawesome-free/js/all.min'

// bulma
import 'bulma/css/bulma.min.css'

// flatpickr
import 'flatpickr/dist/flatpickr.min.css'

// notyf
import 'notyf/notyf.min.css';

import {pt} from "@/i18n/pt";
import {en} from "@/i18n/en"

// i18n options and strings loading
const i18n = createI18n({
    locale: 'pt',
    messages: {
        pt,
        en,
    }
})

createApp(App).use(store).use(router).use(i18n).mount('#app')