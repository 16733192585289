
import {defineComponent} from "vue";
import JitsiIframe from "@/components/JitsiIframe.vue";

export default defineComponent({
  name: 'Meeting',
  components: {JitsiIframe},
  data() {
    return {
      pin: '',
      isChecking: true,
      isCheckingPIN: false,
      isValidPIN: false,
      showWarning: false,
    }
  },
  computed: {
    meetingHash() {
      return this.$store.getters.meetingHash;
    },
    userData() {
      return {
        email: this.$store.getters.meetingEmail,
        name: this.$store.getters.meetingName,
      }
    }
  },
  methods: {
    checkPIN() {
      this.showWarning = false;
      this.isCheckingPIN = true;
      this.$store.dispatch('getMeetingByPIN', this.pin)
          .then((meetingData: any) => {
            this.$store.commit('meetingEmail', meetingData.user_email)
            this.$store.commit('meetingName', meetingData.user_name)
            this.$store.commit('meetingHash', meetingData.hash)
            this.isCheckingPIN = false;

            setTimeout(() => {
              this.isChecking = false;
            }, 2000);
          })
          .catch(() => {
            this.showWarning = true;
            this.isCheckingPIN = false;
          })
    }
  },
  mounted() {
    if (this.$route.params['pin']) {
      this.pin = this.$route.params['pin'].toString()
    }
  }
})
