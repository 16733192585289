
import {defineComponent} from "vue";
import {mapActions} from "vuex";

export default defineComponent({
  name: "Navbar",
  data() {
    return {
      componentName: "Navbar"
    }
  },
  components: {},
  props: [],
  computed: {},
  methods: {
    ...mapActions(['logout']),
    doLogout() {
      this.logout()
      this.$router.push('/login')
    }
  },
  watch: {},
  mounted() {
  }
})
