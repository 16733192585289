<template>
  <div id="mainView"></div>
</template>

<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "JitsiIframe",
  props: ['userData', 'meetingHash'],
  data() {
    return {
      jitsiApp: null
    }
  },
  methods: {
    getVideoContainerSize() {
      const vc = document.querySelector('.video-container');
      return {
        height: vc.offsetHeight,
        width: vc.offsetWidth
      }
    },
    initJitsi() {
      const options = {
        roomName: this.meetingHash,
        width: this.getVideoContainerSize().width,
        height: this.getVideoContainerSize().height,
        parentNode: document.querySelector('#mainView'),
        userInfo: {
          email: this.userData.email,
          displayName: this.userData.name
        },
        configOverwrite: {
          disableDeepLinking: true,
          defaultLanguage: 'en',
          dynamicBrandingUrl: 'https://wisevet.space/static/branding.json',
          disableInitialGUM: true,
          hideParticipantsStats: false,
          remoteVideoMenu:
              {
                disableKick: true,
                disableGrantModerator: true
              },
          prejoinPageEnabled: false,
          disable1On1Mode: false,
          doNotStoreRoom: true,
          toolbarButtons: [
            'microphone',
            'camera',
            'desktop',
            'fullscreen',
            'fodeviceselection',
            'hangup',
            'chat',
            'videoquality',
            'tileview',
            'select-background',
            'sharedvideo',
            'download',
          ],
        },
      };

      this.jitsiApp = new JitsiMeetExternalAPI('meet.fslab.de', options);

      this.jitsiApp.addEventListener('videoConferenceLeft', () => {
        const mainView = document.querySelector('#mainView');
        mainView.style.visibility = "hidden"
        this.$store.commit('meetingHash', '')
      })
    },
  },
  mounted() {
    this.initJitsi()
  }
})
</script>

<style scoped>

</style>
