interface User {
    user_id: number,
    name: string
}

interface UserNameParams {
    user_id: number;
    name: string;
}

interface UserEmailParams {
    user_id: number;
    email: string;
}

interface LoginData {
    email: string;
    password: string;
}

interface UserImageParams {
    user_id: number,
    file: string,
}

import {APIURL} from "@/store/config";

export const users = {
    state: {
        users: [] as User[],
        token: localStorage.getItem('token') || '',
        userData: localStorage.getItem('userData') || '{}'
    },
    mutations: {
        users(state: any, users: User[]) {
            state.users = users
        },
        token(state: any, token: string) {
            state.token = token
            localStorage.setItem('token', token)
        },
        userData(state: any, userData: any) {
            state.userData = JSON.stringify(userData)
            localStorage.setItem('userData', JSON.stringify(userData))
        }
    },
    getters: {
        getUsers(state: any): User[] {
            return state.users
        },
        token(state: any): string {
            return state.token
        },
        userData(state: any): any {
            return JSON.parse(state.userData)
        },
    },
    actions: {
        fetchUsers(state: any) {
            return new Promise((resolve, reject) => {
                fetch(`${APIURL}users`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${state.getters.token}`,
                    }
                })
                    .then(res => res.json())
                    .then(users => {
                        state.commit('users', users)
                        resolve(users)
                    })
                    .catch(err => reject(err))
            })
        },
        async createUser(state: any, newUser: User) {
            const response = await fetch(`${APIURL}users`, {
                method: 'POST',
                body: JSON.stringify(newUser),
                headers: {
                    Authorization: `Bearer ${state.getters.token}`,
                }
            })

            if (response.ok) {
                state.dispatch("fetchUsers")
                return true
            } else {
                throw new Error('error_creating_new_user')
            }
        },
        async deleteUser(state: any, userID: string) {
            const response = await fetch(`${APIURL}users/${userID}`, {
                method: 'DELETE',
                headers: {
                    Authorization: `Bearer ${state.getters.token}`,
                }
            })

            if (response.ok) {
                state.dispatch("fetchUsers")
                return true;
            } else {
                throw new Error('error_removing_user')
            }
        },
        async changeName(state: any, params: UserNameParams) {
            const response = await fetch(`${APIURL}users/name/${params.user_id}`, {
                method: 'POST',
                body: JSON.stringify({
                    name: params.name
                }),
                headers: {
                    Authorization: `Bearer ${state.getters.token}`,
                }
            })

            if (response.ok) {
                return true;
            } else {
                throw new Error('error_changing_user_name')
            }
        },
        async changeEmail(state: any, params: UserEmailParams) {
            const response = await fetch(`${APIURL}users/email/${params.user_id}`, {
                method: 'POST',
                body: JSON.stringify({
                    email: params.email
                }),
                headers: {
                    Authorization: `Bearer ${state.getters.token}`,
                }
            })

            if (response.ok) {
                return true;
            } else {
                throw new Error('error_changing_user_email')
            }
        },
        async login(state: any, params: LoginData) {

            const response = await fetch(`${APIURL}users/login/`, {
                method: 'POST',
                body: JSON.stringify(params)
            })

            if (response.ok) {
                const loginData = await response.json()

                /* store data */
                state.commit('token', loginData.token)
                state.commit('userData', loginData)
            } else {
                throw new Error('invalid_login_attempt')
            }
        },
        logout(state: any) {
            state.commit('userData', {})
            state.commit('token', '')
            localStorage.removeItem('token')
            localStorage.removeItem('userData')
        },
        async uploadUserImage(state: any, params: UserImageParams) {

            const formData = new FormData()
            formData.append('image', params.file)

            const response = await fetch(`${APIURL}users/image/${params.user_id}`, {
                method: 'POST',
                body: formData,
                headers: {
                    Authorization: `Bearer ${state.getters.token}`,
                }
            })

            if (response.ok) {
                return true;
            } else {
                throw new Error('error_changing_user_image')
            }
        }
    }
}